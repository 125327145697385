
.recruiting-wrapper {
    background-color: white;
}

.ressort-wrapper {
    padding: 10em 0;
    background-color: white;
}

.mobile-capable {
    padding-bottom: 0!important;
}

.banner-wrapper {
    background-color: transparent;
    position: relative;
    z-index: -1;
}

.banner-image {
    max-height: 500px;
    object-fit: cover;
    width: 100%;
}

.motivation-wrapper {
    position: relative;
}

.motivation-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -85px;
  width: 150px;
  background: #009FE3;
  transform: skew(-15deg);
}

.motivation {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-right: 50px;
    
    font-size: 4em;
    color: white;
    text-align: center;
}

@media (max-width: 1050px) {
    .motivation {
        font-size: 3em;
    }
}

@media (max-width: 899px) {
    .motivation {
        font-size: 2em;
        position: absolute;
        top: -200px;
        margin: 0 auto;
        width: 100%;
        padding: 1em 0;
        background: rgba(0, 159, 227, 0.8);
    }
    
    .motivation-wrapper:before {
        display: none;
    }
}